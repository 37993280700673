import { loadDocument } from '@cwrc/leafwriter-storage-service/headless';
import { Typography } from '@mui/material';
import { db } from '@src/db';
import { useLeafWriter, usePermalink } from '@src/hooks';
import { useActions } from '@src/overmind';
import { isErrorMessage } from '@src/types';
import { renameFileAsCopy } from '@src/utilities';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export const useLoadResource = () => {
  const { setResource, setIsPrivateCommons } = useActions().editor;
  const { getStorageProviderAuth } = useActions().providers;
  const { loadSample, loadFromUrl } = useActions().storage;
  const { openDialog } = useActions().ui;

  const location = useLocation();
  let [, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { disposeLeafWriter, setFromCloud, setOwner, setRepo, setCloudType, setCloudGitLabHostname, setCloudAccessToken, setIsPrivate } = useLeafWriter();
  const { getResourceFromPermalink } = usePermalink();

  const loadFromPermalink = async () => {
    const resource = await getResourceFromPermalink();
    if (!resource) return showErrorMessage(t('LWC.storage.warning.check_URL_structure'));
    if (isErrorMessage(resource)) {
      showErrorMessage(resource.message);
      return;
    }

    if (resource.category && resource.url) {
      const content = await loadSample(resource.url);
      if (content instanceof Error) return showErrorMessage(content.message);

      setResource({ content, filename: `${resource.title}.xml` });
      return;
    }

    if (resource.isLocal) {
      //remove id from url
      const params = queryString.parse(location.search);
      if (typeof params.filename === 'string') setSearchParams({ filename: params.filename });
      //remove temp
      if (typeof params.id === 'string') await db.documentRequested.delete(params.id);

      setResource(resource);
      return;
    }

    if (!resource.provider) return showErrorMessage(t('LWC.storage.provider not found'));

    //Load document from RAW if user is not signed in
    if (resource.url?.includes('https://raw.githubusercontent')) {
      const content = await loadFromUrl(resource.url);
      if (content instanceof Error) return showErrorMessage(content.message);
      const filename = resource.filename ? renameFileAsCopy(resource.filename) : undefined;
      setResource({ content, filename });
      return;
    }

    const providerAuth = getStorageProviderAuth(resource.provider);
    if (!providerAuth) return showErrorMessage(t('LWC.storage.provider not found'));

    const document = await loadDocument(providerAuth, resource);
    if (document instanceof Error) return showErrorMessage(document.message);
    // Remember all details about the cloud repo we got our document from so we can later also fetch facsimiles from there
    setFromCloud();
    // Remember from which repo the document came, needed to load pictures
    setOwner(document.owner)
    setRepo(document.repo)
    setCloudType(providerAuth.name)
    setCloudGitLabHostname(providerAuth?.GitLabHostname)
    setCloudAccessToken(providerAuth.access_token)
    if (resource.isPrivate == "true") {
      setIsPrivateCommons(true) // Remember it for ourselfs, aka commons, so we can logout the user properly
      setIsPrivate(true) // Remember it for the editor (package: cwrc-leafwriter), so it can better handle loading images

    } else {
      // Documents that are not private, don't get anything
      setIsPrivateCommons(false)
      setIsPrivate(false)
    }
    // console.log(document)
    setResource({...document, isPrivate: resource.isPrivate});
  };

  const showErrorMessage = (message: string) => {
    openDialog({
      props: {
        maxWidth: 'xs',
        preventEscape: true,
        severity: 'error',
        title: `${t('LWC.storage.invalid_request')}`,
        Body: () => (
          <Typography sx={{ '::first-letter': { textTransform: 'uppercase' } }}>
            {message}
          </Typography>
        ),
        onClose: async () => {
          disposeLeafWriter();
          navigate('/', { replace: true });
        },
      },
    });
  };

  return {
    loadFromPermalink,
  };
};
