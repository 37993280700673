import CitationDialog from './CitationDialog';
import CorrectionDialog from './CorrectionDialog';
import DateDialog from './DateDialog';
import KeywordDialog from './KeywordDialog';
import LinkDialog from './LinkDialog';
import NoteDialog from './NoteDialog';
import OrganizationDialog from './OrganizationDialog';
import PersonDialog from './PersonDialog';
import PlaceDialog from './PlaceDialog';
import ThingDialog from './ThingDialog';
import WorkDialog from './WorkDialog';

export { CitationDialog };
export { DateDialog };
export { KeywordDialog };
export { LinkDialog };
export { NoteDialog };
export { OrganizationDialog };
export { PersonDialog };
export { PlaceDialog };
export { ThingDialog };
export { WorkDialog };

export default {
  citation: CitationDialog,
  correction: CorrectionDialog,
  date: DateDialog,
  keyword: KeywordDialog,
  link: LinkDialog,
  note: NoteDialog,
  organization: OrganizationDialog,
  person: PersonDialog,
  place: PlaceDialog,
  thing: ThingDialog,
  work: WorkDialog,
};
