import { log } from '@src//utilities';
import { clearCache } from '@src/db';
import type { AnnotationUserProfileProps, User } from '@src/types';
import Cookies from 'js-cookie';
import { Context } from '../index';
import type { LinkedAccount } from './effects';

//* INIITIALIZE
export const onInitializeOvermind = async ({ actions, effects }: Context, overmind: any) => {
  // Setup API
  await effects.auth.api.setup();

  //Get LINCS Providers
  const providers = await effects.auth.api.getProviders();

  //populate supported providers
  if (!(providers instanceof Error) && providers.length > 0) actions.providers.setup(providers);

  //Authenticate
  await actions.auth.authenticateUser();
};

//* AUTHENTICATION

export const authenticateUser = async ({ state, actions, effects }: Context) => {
  state.auth.userState = 'AUTHENTICATING';
  const sessionAuthenticated = await effects.auth.api.init();

  if (!sessionAuthenticated) {
    state.auth.userState = 'UNAUTHENTICATED';
    return;
  }

  const token = await effects.auth.api.getToken();
  if (!token) {
    state.auth.userState = 'UNAUTHENTICATED';
    return log.warn('No Authentication token');
  }

  //Identity provider
  const identityProvider = await actions.auth.setupMainIdentityProvider(token);
  if (!identityProvider) {
    state.auth.userState = 'UNAUTHENTICATED';
    return;
  }

  await actions.auth.setUserProfile(identityProvider);

  // Timer service to periodically check and if necessary renew token from Keycloak so we still 
  //have a working Keycloak connection when and if we need to renew token for external storage provider
  state.auth.timerServiceKeycloak.onTimer.subscribe(async () => {
    if (effects.auth.api.isTokenExpired()) {
      let result = await effects.auth.api.updateToken()
    }
  });

  state.auth.timerServiceKeycloak.start()

  state.auth.userState = sessionAuthenticated ? 'AUTHENTICATED' : 'UNAUTHENTICATED';
};

export const getKeycloakAuthToken = async ({ effects }: Context) => {
  const token = await effects.auth.api.getToken();
  return token;
};

export const setupMainIdentityProvider = async ({ actions, effects }: Context, token: string) => {
  const identity_provider = effects.auth.api.getIdentityProvider();
  if (!identity_provider) return log.warn('No identity_provider');

  const IDPTokens = await effects.auth.api.getExternalIDPTokens(identity_provider, token);

  if (typeof IDPTokens !== 'string' && 'error' in IDPTokens) return 'none';

  if (!IDPTokens) return log.warn('No identity_provider tokens');

  const provider = await actions.providers.initProvider({
    IDPTokens,
    providerName: identity_provider,
  });

  if (!provider?.service) {
    log.warn(`Identity Provider ${identity_provider} is not supported`);
    return;
  }

  return identity_provider;
};

export const setUserProfile = async (
  { state, actions, effects }: Context,
  identityProvider: string,
) => {
  const keyCloakProfile = await effects.auth.api.getUserData();
  const user = keyCloakProfile as User;
  state.auth.user = user;

  //augment user profile
  state.auth.user.identities = new Map();
  const linkedAccounts = await actions.auth.getLinkedAccounts();

  //in case the user have unlinked the main provider from its account.
  if (identityProvider === 'none' && linkedAccounts) {
    identityProvider = linkedAccounts[0].identityProvider!;
  }

  if (!identityProvider) return;

  //preferredID
  const preferredID = effects.storage.api.getFromLocalStorage('prefIdProvider');

  //if not preferredID, use the first identityProviders linked Account
  if (preferredID) {
    state.auth.user.preferredID = preferredID;
    state.auth.user.preferredID_name = effects.storage.api.getFromLocalStorage('prefIdProvider_name')
  } else {
    actions.auth.setPreferredId(identityProvider);
  }

  //use avatar from preffed ID
  state.auth.user.avatar_url = user.identities.get(user.preferredID)?.avatar_url ?? undefined;

  //* Prefer Storage

  const { storageProviders } = state.providers;

  //get preferred storage if available
  let prefStorageProvider = effects.storage.api.getFromLocalStorage('prefStorageProvider');

  //If no prefStorageProvider use preferId to define prefStorage
  if (!prefStorageProvider) {
    if (storageProviders.some((provider) => provider.providerId === preferredID)) {
      prefStorageProvider = preferredID;
    } else {
      //If preferId is not a storage provider, use the first one available
      const firstAvailableStorageSupported = storageProviders.find(
        (provider) => !!provider.service,
      );
      if (firstAvailableStorageSupported?.providerId) {
        prefStorageProvider = firstAvailableStorageSupported.providerId;
      }
    }
  }

  //
  if (prefStorageProvider) actions.storage.setPrefStorageProvider(prefStorageProvider);
};

export const linkAccount = async (
  { state, actions, effects }: Context,
  identity_provider: string,
) => {
  const token = await effects.auth.api.getToken();
  if (!token) return log.warn('No Authentication token');
  if (!state.auth.user?.username) return log.warn('User not auhtenticated');

  const linkAccountUrl = await effects.auth.api.getLinkAccountUrl({
    username: state.auth.user.username,
    provider: identity_provider,
    keycloakAccessCode: token,
  });
  if (typeof linkAccountUrl !== 'string') {
    const { message } = linkAccountUrl.error;
    actions.ui.emitNotification({ message });
    return;
  }

  return linkAccountUrl;
};

export const getLinkedAccounts = async ({ state, actions, effects }: Context) => {
  if (!state.auth.user) return;

  const token = await effects.auth.api.getToken();
  if (!token) return log.warn('No Authentication token');

  const linkedAccounts = await effects.auth.api.getLinkedAccounts(token, state.auth.user.username);
  if ('error' in linkedAccounts) {
    const { message } = linkedAccounts.error;
    actions.ui.emitNotification({ message });
    return;
  }

  if (linkedAccounts.length === 0) return;

  for await (const account of linkedAccounts) {
    //IDENTITY
    const providerName = account.identityProvider;
    if (!providerName) continue;
    if (state.auth.user.identities.get(providerName)) continue;

    if (!actions.providers.isProviderInitilized(providerName)) {
      await actions.auth.setupLinkedAccountProvider(account);
    }

    const userDetails = await actions.auth.getUserDetails(account);
    if (!userDetails) continue;
    state.auth.user.identities.set(providerName, userDetails);
  }

  return linkedAccounts;
};

export const getUserDetails = async (
  { state }: Context,
  { identityProvider: providerName, userId }: LinkedAccount,
) => {
  if (!providerName) return;

  const { supportedProviders } = state.providers;

  const provider = supportedProviders.find((p) => p.providerId === providerName && p.service);
  if (!provider?.service) return;

  const userDetails = await provider.service.getAuthenticatedUser(userId);
  if (!userDetails) return;

  if (state.auth.user) state.auth.user.identities.set(providerName, userDetails);
  return userDetails;
};

export const setupLinkedAccountProvider = async (
  { actions, effects }: Context,
  { identityProvider: providerName, userId, userName }: LinkedAccount,
) => {
  if (!providerName) return;

  const token = await effects.auth.api.getToken();
  if (!token) return log.warn('No Authentication token');

  const IDPTokens = await effects.auth.api.getExternalIDPTokens(providerName, token);
  if (typeof IDPTokens !== 'string' && 'error' in IDPTokens) {
    const { message } = IDPTokens.error;
    actions.ui.emitNotification({ message });
    return;
  }

  if (!IDPTokens) return log.warn('No identity_provider tokens');

  const provider = await actions.providers.initProvider({
    IDPTokens,
    providerName,
    userId,
    userName,
  });

  if (!provider?.service) log.warn(`Identity Provider ${providerName} is not supported`);
};

export const getUserProfile = ({ state }: Context) => {
  const { user } = state.auth;
  if (!user || user.identities.size === 0) return;

  const preferredID = user.preferredID;

  const name = user.identities.get(preferredID)?.name;
  const url = user.identities.get(preferredID)?.uri;

  if (!name || !url) return;

  const username = user.identities.get(preferredID)?.username;
  const avatar_url = user.avatar_url;
  const email = user.email;

  const annotationUserProfile: AnnotationUserProfileProps = {
    name,
    url,
    avatar_url,
    email,
    preferredID,
    username,
  };

  return annotationUserProfile;
};

//* USER

export const signIn = ({ effects }: Context, options?: { idpHint?: string }) => {
  effects.auth.api.login(options);
};

export const accountManagement = ({ effects }: Context) => {
  effects.auth.api.accountManagement();
};

export const setPreferredId = ({ state, effects }: Context, providerId: string) => {
  if (!state.auth.user) return;

  // Get (if applicable) human readable name of choosen provider
  const GitLabInstanceName = state.providers.authProviders.find(x => x.providerId === providerId).GitLabInstanceName;

  state.auth.user.preferredID = providerId;
  state.auth.user.preferredID_name = GitLabInstanceName ? GitLabInstanceName: providerId;
  effects.storage.api.saveToLocalStorage('prefIdProvider', providerId);
  effects.storage.api.saveToLocalStorage('prefIdProvider_name', GitLabInstanceName ? GitLabInstanceName: providerId);

  state.auth.user.avatar_url = state.auth.user.identities.get(providerId)?.avatar_url ?? undefined;

  return providerId;
};

export const signOut = async ({ effects }: Context, redirectPath?: string) => {
  effects.storage.api.clearLocalStorage();
  Cookies.remove('resource');

  //* Clear IndexedDB tabels.
  // Including the ones created by LEAF-Writer and Leafwriter Storage Service
  await clearCache();
  // Check if we got a redirect path on logout, that we have to observe.
  if (redirectPath) {
    await effects.auth.api.logout({redirectPath: redirectPath});  
  } else {
    await effects.auth.api.logout();
  }
};
